import {SvgIconProps} from '@mui/material';
import {
  EnterpriseLightTheme,
  SelectDropdownProps,
} from '@verily-src/react-design-system';
import {BehaviorSubject, Observable} from 'rxjs';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CallbackFunctionVariadicAnyReturn = (...args: any[]) => any;

/**
 * Accepted props from rendering application.
 * @property {string} logo - Logo shown beside the title of the nav. Value is the string is the RDS icon id (e.g., 'AddReactionIcon').
 * @property {string} darkLogo - Logo shown beside the title of the nav. Value is the string is the RDS icon id (e.g., 'AddReactionIcon').
 *  If provided, will be used when the browser prefers dark mode.
 * @property {boolean} showDividers - Enables dividers below Header, and between Item Groups. Divider over footer is always shown.
 * @property {boolean} isResponsive - Set flag to true to enable the responsive navbar feature. When the
 * width of any screen is less than or equal 768px, the responsive feature will accommodate it as a mobile
 * device. Only simple NavItems will be displayed on mobile - no header, footer, or groups.
 * @property {string} homePath - the path to return to when the logo is clicked. Defaults to '/'
 * @property {Theme} theme - The theme with which to render the Nav Bar.
 * @property {string} themeName - The name of the theme with which to render the Nav Bar. Using a
 * string instead of a theme object allows a root-config to drop RDS and decrease bundle size.
 * @property {string} darkModeThemeName - The name of the dark mode theme with which to render the Nav Bar. Only used if the browser prefers dark mode.
 * @property {string} locale - The locale to use for the nav bar.
 * @property {CallbackFunctionVariadicAnyReturn} logoutCallback - Shows logout button on the navigation footer, and calls logoutCallback function.
 * @property {HideOn[]} hideOn - List of paths to hide the nav on.
 */
type NavProps = {
  logo?: string;
  darkLogo?: string;
  showDividers?: boolean;
  isResponsive?: boolean;
  homePath?: string;
  theme?: typeof EnterpriseLightTheme;
  themeName?: string;
  darkModeThemeName?: string;
  locale?: string;
  logoutCallback?: CallbackFunctionVariadicAnyReturn;
  hideOn?: HideOn[];
  logoProps?: SvgIconProps;
};

/**
 * Defines a path to hide the nav on.
 * @property {string} path - Path to hide nav on. (ex. "/pathname")
 * @property {boolean} isPrefix - If true, will hide nav on all paths that start with the provided path. If false, will only hide nav on exact match.
 */
type HideOn = {
  path: string;
  isPrefix?: boolean;
};

/**
 * Wraps a navigation item, providing details for rendering.
 * If nav item does not have children, it should have a path defined.
 * If nav item has children, it should not have a path defined.
 * If nav item is a child item, it should not have children or icon defined.
 * @property {string} name - Name to display for nav item. Expected to be unique.
 * @property {number} order - Order the item should be placed in, used for sorting. Lower value results in being placed higher on Nav. Supports values [Number.MIN_VALUE, Number.MAX_VALUE].
 * @property {string} path - Path to link to. Redirection is handled by single-spa. Can work locally by "/pathname" or globally by "https://example.com"
 * @property {string} icon - Icon to be displayed beside nav item label (e.g., 'AddReactionIcon').
 * @property {NavItem[]} children - List of children nav items to show in dropdown. If present will not redirect to path on click, will instead open dropdown. Default to [].
 */
type NavItem = {
  name: string;
  order: number;
  path?: string;
  icon?: string;
  selectedIcon?: string;
  children?: NavItem[];
};

/**
 * Wraps a group of navigation items. It is an rxJs observable for reactive changes.
 * @property {NavItem[]} items - List of navigation items to render in group.
 */
type RouteConfig = {
  items: BehaviorSubject<NavItem[]>;
};

/**
 * Provides details for rendering a header slot dropdown.
 * @property {Option[]} options - List of dropdown options to render.
 * @property {string} ariaLabel - Aria label for the dropdown.
 * @property {HeaderSlotDropdownCallback} callback - Callback function to call when an option is selected.
 * @property {string} defaultValue - Default value to display in dropdown.
 */
type HeaderSlotDropdownConfigs = {
  options: SelectDropdownProps['options'];
  ariaLabel: string;
  onSelectOptionCallback: (value: string) => void;
  defaultValue: string;
};

/**
 * Provides details for rendering a header slot.
 * @property {string} name - Name to display for slot.
 * @property {string} tooltip - Tooltip to display on hover.
 * @property {string} icon - Icon to display for slot when nav is collapsed.
 */
type HeaderSlotItem = {
  name: string;
  tooltip: string;
  icon: string;
  dropdownConfigs?: HeaderSlotDropdownConfigs;
};

/**
 * Wraps a group of navigation items.
 * @property {string} name - Name to display for group. Expected to be unique among groups.
 * @property {number} order - Order the group should be placed in, used for sorting. Lower value results in being placed higher on Nav. Note groups are placed below list of non-group items. Supports values [Number.MIN_VALUE, Number.MAX_VALUE].
 * @property {NavItem[]} items - List of navigation items to render in group.
 */
type NavItemGroup = {
  name: string;
  order: number;
  items: NavItem[];
};

/**
 * Sorts by order property, if equal then sort alphabetically by name property.
 */
const sortByOrderThenAlpha = (
  a: NavItem | NavItemGroup,
  b: NavItem | NavItemGroup
) => {
  if (a.order === b.order) return a.name.localeCompare(b.name);
  return a.order - b.order;
};

/**
 * Wrapper class for navigation state.
 * Provides accessors to observables for items, item groups, and footerItems.
 * Allows items/item groups/footerItems to be added/updated/removed.
 */
class Nav {
  // We could also use a Set to ensure no duplication...
  protected navItems: NavItem[] = [];
  private navItems$: BehaviorSubject<NavItem[]>;
  protected navItemGroups: NavItemGroup[] = [];
  private navItemGroups$: BehaviorSubject<NavItemGroup[]>;
  protected footerNavItems: NavItem[] = [];
  private footerNavItems$: BehaviorSubject<NavItem[]>;
  protected navHeaderSlotItem: HeaderSlotItem | null = null;
  private navHeaderSlotItem$: BehaviorSubject<HeaderSlotItem | null>;

  constructor() {
    this.navItems$ = new BehaviorSubject<NavItem[]>([]);
    this.navItemGroups$ = new BehaviorSubject<NavItemGroup[]>([]);
    this.footerNavItems$ = new BehaviorSubject<NavItem[]>([]);
    this.navHeaderSlotItem$ = new BehaviorSubject<HeaderSlotItem | null>(null);
  }

  get items(): Observable<NavItem[]> {
    return this.navItems$;
  }

  get itemGroups(): Observable<NavItemGroup[]> {
    return this.navItemGroups$;
  }

  get footerItems(): Observable<NavItem[]> {
    return this.footerNavItems$;
  }

  get headerSlotItem(): Observable<HeaderSlotItem | null> {
    return this.navHeaderSlotItem$;
  }

  private orderItems() {
    this.navItems.sort(sortByOrderThenAlpha);
  }

  private orderFooterItems() {
    this.footerNavItems.sort(sortByOrderThenAlpha);
  }

  private orderItemGroups() {
    this.navItemGroups.sort(sortByOrderThenAlpha);
  }

  private orderItemsInGroup(name: string) {
    const group = this.navItemGroups.find(
      (itemGroup) => itemGroup.name === name
    );
    if (!group) throw Error(`No group found matching name: ${name}`);
    group.items.sort(sortByOrderThenAlpha);
  }

  /**
   * Checks if item is already present by name, if so then updates item
   * otherwise adds item to end of list.
   * Does not support reactivity natively. Expectation is to push updated list on subject.
   */
  private insertItem(
    itemList: Array<NavItem | NavItemGroup>,
    item: NavItem | NavItemGroup
  ) {
    const index = itemList.findIndex((navItem) => navItem.name === item.name);
    if (index === -1) {
      itemList.push(item);
      return;
    }

    itemList[index] = item;
  }

  private insertItemGroup(itemGroupList: NavItemGroup[], group: NavItemGroup) {
    this.insertItem(itemGroupList, group);
  }

  private pushUpdatedItemGroups() {
    this.navItemGroups$.next(this.navItemGroups);
  }

  private pushUpdatedItems() {
    this.navItems$.next(this.navItems);
  }

  private pushUpdatedFooterItems() {
    this.footerNavItems$.next(this.footerNavItems);
  }

  addItemGroup(itemGroup: NavItemGroup) {
    this.addItemGroups([itemGroup]);
  }

  private addItemToGroup(item: NavItem, groupName: string) {
    const group = this.navItemGroups.find(
      (itemGroup) => itemGroup.name === groupName
    );
    if (!group) throw Error(`No group named ${groupName} found.`);
    this.insertItem(group.items, item);
    this.orderItemsInGroup(groupName);
    this.pushUpdatedItemGroups();
  }

  private addItemsToGroup(items: NavItem[], groupName: string) {
    const group = this.navItemGroups.find(
      (itemGroup) => itemGroup.name === groupName
    );
    if (!group) throw Error(`No group named ${groupName} found.`);

    items.forEach((item) => this.insertItem(group.items, item));

    this.orderItemsInGroup(groupName);
    this.pushUpdatedItemGroups();
  }

  addItemGroups(itemGroups: NavItemGroup[]) {
    itemGroups.forEach((group) => {
      this.insertItemGroup(this.navItemGroups, group);
      this.orderItemsInGroup(group.name);
    });
    this.orderItemGroups();
    this.pushUpdatedItemGroups();
  }

  addItem(item: NavItem, groupName?: string) {
    if (groupName) this.addItemToGroup(item, groupName);
    this.addItems([item], groupName);
  }

  /**
   * Adds all items then sorts list and updates subscription.
   * More efficient then calling addItem in sequence.
   */
  addItems(items: NavItem[], groupName?: string) {
    if (groupName) {
      this.addItemsToGroup(items, groupName);
      return;
    }
    items.forEach((item) => this.insertItem(this.navItems, item));
    this.orderItems();
    this.pushUpdatedItems();
  }

  /**
   * Searches an item list for an item matching by name, then adds childItem and
   * resorts the children.
   * Does NOT push updated list, callee should do this when necessary.
   */
  private addChildItem(itemList: NavItem[], item: NavItem, childItem: NavItem) {
    const index = itemList.findIndex((navItem) => navItem.name === item.name);
    if (index === -1) {
      this.insertItem(itemList, item);
      this.addChildItem(itemList, item, childItem);
      return;
    }
    if (!itemList[index].children) itemList[index].children = [];
    itemList[index].children!.push(childItem);
    itemList[index].children!.sort(sortByOrderThenAlpha);
  }

  /**
   * Adds a child item to a nav item list of children. Nav item may be present
   * in list of items, or within an item group.
   * If no nav item is found, add a new one.
   */
  addChildToItem(item: NavItem, childItem: NavItem) {
    // Check groups to find item, if no item present check item list.
    // If no matching item found at all, default to add to list.
    const groupIndex = this.navItemGroups.findIndex((group) =>
      group.items.includes(item)
    );

    if (groupIndex !== -1) {
      this.addChildItem(this.navItemGroups[groupIndex].items, item, childItem);
      this.pushUpdatedItemGroups();
      return;
    }

    this.addChildItem(this.navItems, item, childItem);
    this.pushUpdatedItems();
    return;
  }

  /**
   * Removes a single item and pushes updated list.
   */
  removeItem(item: NavItem, groupName?: string) {
    if (groupName) {
      this.removeItemFromGroup(item, groupName);
      return;
    }
    const index = this.navItems.findIndex(
      (navItem) => navItem.name === item.name
    );
    if (index === -1) return;
    this.navItems.splice(index, 1);
    this.pushUpdatedItems();
  }

  /**
   * Removes all item groups in provided list.
   */
  removeItemGroups(groups: NavItemGroup[]) {
    this.navItemGroups = this.navItemGroups.filter(
      (group) => !groups.includes(group)
    );
    this.pushUpdatedItemGroups();
  }

  /**
   * Finds item in group and removes, looks for exact match.
   */
  private removeItemFromGroup(item: NavItem, groupName: string) {
    const index = this.navItemGroups.findIndex(
      (itemGroup) => itemGroup.name === groupName
    );
    if (index === -1) throw Error(`No group named ${groupName} found.`);

    this.navItemGroups[index].items = this.navItemGroups[index].items.filter(
      (itemInGroup: NavItem) => {
        return itemInGroup !== item;
      }
    );
    this.pushUpdatedItemGroups();
  }

  /**
   * Filters out provided footer items. Then pushes updated list.
   */
  removeFooterItems(items: NavItem[]) {
    this.footerNavItems = this.footerNavItems.filter(
      (item) => !items.includes(item)
    );
    this.pushUpdatedFooterItems();
  }

  /**
   * Adds a single footer item, sorts then pushes update.
   */
  addFooterItem(item: NavItem) {
    this.addFooterItems([item]);
  }

  /**
   * Adds a single header slot item.
   */
  addHeaderSlotItem(item: HeaderSlotItem) {
    this.navHeaderSlotItem$.next(item);
  }

  /**
   * Adds multiple footer items, then sorts and pushes updated list.
   */
  addFooterItems(items: NavItem[]) {
    items.forEach((item) => this.insertItem(this.footerNavItems, item));
    this.orderFooterItems();
    this.pushUpdatedFooterItems();
  }

  /**
   * Clears the Navigation Panel. Should only be used for testing.
   */
  clear() {
    this.footerNavItems = [];
    this.navItems = [];
    this.navItemGroups = [];
    this.pushUpdatedFooterItems();
    this.pushUpdatedItems();
    this.pushUpdatedItemGroups();
  }
}

/**
 * This type covers the public api of the nav instance but sets the return type to any to allow
 * the mock to match the interface and use jest.Mock typings.
 */
type NavTestAPI = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [K in keyof typeof nav]: any;
};

const nav = new Nav();

export default nav;

export {
  HideOn,
  nav as Nav,
  Nav as NavClass,
  NavItem,
  NavItemGroup,
  NavProps,
  NavTestAPI,
  RouteConfig,
  HeaderSlotItem,
  HeaderSlotDropdownConfigs,
};
